<template></template>

<script>
  export default {
    created() {
      this.$store.commit('auth/setAuthCode', null)
      this.$store.commit('auth/setUser', null)

      this.$router.push(this.$route.params.slug ? `/${this.$route.params.slug}/auth/login`: '/auth/login')
    }
  }
</script>