<template>
  <navigation-layout>
    <centered-form-layout
      @submit.prevent="submit"
      :title="$t('auth.reset_password')">
      <form-message
        class="my-4"
        v-if="error">
        <template v-if="error === 'password_confirm'">{{ $t('auth.set_password_confirm_failed') }}</template>
        <template v-if="error === 'invalid_request'">{{ $t('auth.invalid_request') }}</template>
      </form-message>
      <form-row>
        <form-input
          type="password"
          :required="true"
          v-model="password">
          {{ $t('generic.password') }}
        </form-input>
      </form-row>
      <form-row class="mt-4">
        <form-input
          type="password"
          :required="true"
          v-model="passwordConfirm">
          {{ $t('generic.password_confirm') }}
        </form-input>
      </form-row>
      <form-row>
        <c-button
          class="mt-4"
          type="submit"
          :disabled="loading">
          {{ $t('auth.set_password') }}
        </c-button>
      </form-row>
    </centered-form-layout>
  </navigation-layout>
</template>

<script>
  import DesignMixin from '../../mixin/design'

  export default {
    data() {
      return {
        error: null,
        password: null,
        loading: false,
        passwordConfirm: null,
      }
    },
    async created() {
      await this.applyDesign()
    },
    methods: {
      async submit() {
        this.error = null
        // Validate same password & m=Parameter
        if (this.password !== this.passwordConfirm) {
          this.error = 'password_confirm'
          return
        }

        // Check if it m query param is set
        if (!this.$route.query.m) {
          this.error = 'invalid_request'
          return
        }

        this.loading = true
        let response = await this.$api.setPassword(this.$route.query.m, this.password)
        if (response && response.ok) {
          this.$toast.success(this.$t('auth.set_password_success_message'), { position: 'top-right'})
          if (this.$route.params.slug) {
            return this.go(`/${this.$route.params.slug}/auth/login`)
          }
          return this.go('/auth/login')
        }
        this.loading = false
      }
    },
    mixins: [
      DesignMixin
    ],
    inject: [
      '$helper',
      '$api',
    ]
  }
</script>